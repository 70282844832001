
body{
  box-sizing: border-box;
  width:100%;
  overflow-x:hidden;
}
.App {
  min-height: 100vh;
  font-size: calc(10px + 2vmin);
  color: white;
  background: #000;
}
 
.App-link {
  text-decoration: none;
}
.previewlinkbox{
  border-radius:7px;
  border: 4px solid #df272c;
  background:#000;
  padding:20px;
  line-height: 14px!important;
  margin-bottom: 30px;

}


.title{

  font-size:4.5em!important;
  letter-spacing: 15px;
}
.tinylinkbox{
  border-radius:7px;
  border: 4px solid #df272c;
  background:#000;
  padding:20px;
  line-height: 14px!important;
  text-align: center;

}
.previewlink{
  white-space: pre-wrap;       /* css-3 */
  white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
  white-space: -pre-wrap;      /* Opera 4-6 */
  white-space: -o-pre-wrap;    /* Opera 7 */
  word-wrap: break-word;       /* Internet Explorer 5.5+ */
  color: #fff;
  margin-bottom: 30px;
  font-size: 16px;
}
.tinylinkbox .previewlink{

  font-size:30px;
  margin-right: 30px;
  font-family: 'Oxanium';
  text-decoration: none;
  text-align: center;
  line-height: 1.6;
  margin-bottom: 0px;
}
.tinylinkbox .copyButton{
  margin-right: 0px;

}
.previewlink:hover{
  color: #df272c;
}
label{
  display:block!important;
  margin-right:20px!important;
  margin-top:25px!important;

}
div{
  box-sizing: border-box;
}

button{
  cursor:pointer;
}

@media screen and (max-width: 64em){
  html,body{
    overflow-x:hidden;
    width:100%;
  }

  .App{
    padding:10px;
  }
  .logoflex, .mainflex{
    padding: 0;
    flex-direction: column;
  }
  .mainflex > div{
    padding:0;
  }
  .flexfield{
    padding: 0;
    flex-direction: column;

  }
  .tinylinkbox, .previewlinkbox p {
    word-break: break-word;
    white-space: normal;
    line-height: 1!important;
  }
.buttonsholder a, .buttonsholder button{
  width:100%;
  display:block;
  margin-bottom: 10px;
}
  label{
    margin:0!important;      
    width:auto;
    text-align: left!important;
  }
  .previewFlex{
    flex-direction: column!important;
  }
  input,textarea{
    margin: 10px  0!important;
    padding:5px!important;
    font-size:14px!important;
  }

  .titlewrapper{
    padding:0!important;
  }

  .title{
    margin-top:20px!important;
    margin-bottom: 20px!important;

    font-size:2.5em!important;
  }
}